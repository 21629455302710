@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

.root {
  width: 100%;
  color: color.$black-200;
}

.tabContentHeader {
  margin-top: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  @include typo.font-20-600;
}

.tabContentHeaderController {
  @include typo.font-14-500;
  display: grid;
  align-items: center;
  gap: 10px;
  grid-template-columns: 50px 180px;
}

.chartWrapper {
  padding-top: 45px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.pieLegendInfo {
  @include typo.font-12-400;
  display: flex;
}

.pieLegend {
  display: flex;
  justify-content: center;
  gap: 50px;
  flex: 1 1 0;
}

.pieLegendItem {
  display: flex;
  justify-content: center;
  align-items: center;
}

.bullet {
  height: 12px;
  width: 12px;
  border-radius: 6px;
  margin-right: 10px;
}

.tableHeader {
  margin-top: 30px;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
}

.downloadButton {
  text-decoration: underline;
  display: flex;
  align-items: center;
  gap: 10px;
  @include typo.font-12-700;
  color: color.$default-font-color;
  cursor: pointer;
  &:hover {
    opacity: 0.7;
  }
  .downloadIconWrapper {
    width: 20px;
    height: 20px;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: color.$default-black-color;
    .downloadIcon {
      width: 16px;
      height: 16px;
      color: white;
    }
  }
}

.tableWrapper {
  border-radius: 4px;
  border: 1px solid color.$gray-900;
  margin-bottom: 60px;
}

.companyLink {
  color: color.$secondary-color;
  text-decoration: underline;
  text-overflow: ellipsis;
  overflow-x: hidden;
  white-space: nowrap;
  cursor: pointer;
}