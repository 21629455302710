@use '/src/style/css/variable/color.scss';

@mixin font-family-roboto {
  font-family: Roboto, NotoSansJP;
}

@mixin font-45-500 {
  @include font-family-roboto;
  font-size: 45px;
  font-weight: 500;
}

@mixin font-42-700 {
  @include font-family-roboto;
  font-size: 42px;
  font-weight: 700;
}

@mixin font-32-700 {
  @include font-family-roboto;
  font-size: 32px;
  font-weight: 700;
}

@mixin font-28-700 {
  @include font-family-roboto;
  font-size: 28px;
  font-weight: 700;
}

@mixin font-28-600 {
  @include font-family-roboto;
  font-size: 28px;
  font-weight: 600;
}

@mixin font-24-700 {
  @include font-family-roboto;
  font-size: 24px;
  font-weight: 700;
}

@mixin font-24-600 {
  @include font-family-roboto;
  font-size: 24px;
  font-weight: 600;
}

@mixin font-24-500 {
  @include font-family-roboto;
  font-size: 24px;
  font-weight: 500;
}

@mixin font-20-700 {
  @include font-family-roboto;
  font-size: 20px;
  font-weight: 700;
}

@mixin font-20-600 {
  @include font-family-roboto;
  font-size: 20px;
  font-weight: 600;
}

@mixin font-20-500 {
  @include font-family-roboto;
  font-size: 20px;
  font-weight: 500;
}

@mixin font-20-400 {
  @include font-family-roboto;
  font-size: 20px;
  font-weight: 400;
}

@mixin font-18-500 {
  @include font-family-roboto;
  font-size: 18px;
  font-weight: 500;
}

@mixin font-17-700 {
  @include font-family-roboto;
  font-size: 17px;
  font-weight: 700;
}

@mixin font-17-400 {
  @include font-family-roboto;
  font-size: 17px;
  font-weight: 400;
}

@mixin font-16-700 {
  @include font-family-roboto;
  font-size: 16px;
  font-weight: 700;
}

@mixin font-16-600 {
  @include font-family-roboto;
  font-size: 16px;
  font-weight: 600;
}

@mixin font-16-500 {
  @include font-family-roboto;
  font-size: 16px;
  font-weight: 500;
}

@mixin font-16-400 {
  @include font-family-roboto;
  font-size: 16px;
  font-weight: 400;
}

@mixin font-14-700 {
  @include font-family-roboto;
  font-size: 14px;
  font-weight: 700;
}

@mixin font-14-600 {
  @include font-family-roboto;
  font-size: 14px;
  font-weight: 600;
}

@mixin font-14-500 {
  @include font-family-roboto;
  font-size: 14px;
  font-weight: 500;
}

@mixin font-14-400 {
  @include font-family-roboto;
  font-size: 14px;
  font-weight: 400;
}

@mixin font-12-700 {
  @include font-family-roboto;
  font-size: 12px;
  font-weight: 700;
}

@mixin font-12-600 {
  @include font-family-roboto;
  font-size: 12px;
  font-weight: 600;
}

@mixin font-12-500 {
  @include font-family-roboto;
  font-size: 12px;
  font-weight: 500;
}

@mixin font-12-400 {
  @include font-family-roboto;
  font-size: 12px;
  font-weight: 400;
}

@mixin font-11-500 {
  @include font-family-roboto;
  font-size: 11px;
  font-weight: 500;
}

@mixin font-10-500 {
  @include font-family-roboto;
  font-size: 10px;
  font-weight: 500;
}

@mixin font-10-400 {
  @include font-family-roboto;
  font-size: 10px;
  font-weight: 400;
}
