@use '~react-virtualized/styles';
@use '/src/style/css/typo.scss';
@use '/src/style/css/variable/color.scss';

// reference from https://github.com/bvaughn/react-virtualized/issues/291
.canvasHack {
  z-index: 1;
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  will-change: scroll-position;
}

.gridHeader {
  overflow-x: hidden !important;
  background-color: color.$black-100;
  &.lightTheme {
    background-color: inherit;
    box-shadow: inset 0 -1px 0 0 color.$gray-900;
  }
}

.gridHeaderCell {
  @include typo.font-16-400;
  line-height: normal;
  letter-spacing: 0px;
  color: white;
  font-weight: normal;
  height: 100%;
  margin: 0px;
  padding: 0 20px;
  background-color: color.$black-100;
  display: flex;
  align-items: center;
  position: inherit;
  :first-of-type {
    margin-left: 0px;
  }
  &.lightTheme {
    background-color: inherit;
    color: color.$gray-200;
    @include typo.font-14-400;
  }
}

.scrollbarPadding {
  padding-right: 20px;
}

.gridBody {
}

.bodyBorder {
  box-shadow: inset 0 0 0 1px color.$gray-800;
}

.lastRow {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.gridBodyCell {
  @include typo.font-14-400;
  line-height: normal;
  letter-spacing: 0px;
  color: color.$default-font-color;
  height: 100%;
  margin: 0px;
  padding: 0 20px;
  display: flex;
  align-items: center;
  position: inherit;
  &:first-of-type {
    margin-left: 0px;
  }
  &.lightTheme {
    background-color: white;
    color: color.$black-200;
    @include typo.font-14-500;
  }
}

.cellData {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.noContents {
  width: 100%;
  height: 100%;
  font-size: 13px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.iconSortArea {
  width: 16px;
  display: inline-flex;
  justify-content: center;
}

.iconAsc {
  color: color.$white-500;
}
.iconDesc {
  color: color.$white-500;
  transform: rotatex(180deg);
}

.fontBold {
  font-weight: 600;
}

.flexAlignCenter {
  justify-content: center;
}

.flexAlignRight {
  justify-content: flex-end;
}

.flexTable {
  > div {
    width: 100% !important;
    max-width: 100% !important;
  }
}

.pointerCursor {
  cursor: pointer;
}

.columnSeperator {
  box-shadow: -1px 0px color.$gray-800;
  &.lightTheme {
    box-shadow: -1px 0px color.$gray-900;
  }
}

.rowSeperator {
  border-bottom: 1px solid color.$gray-800;
  &.lightTheme {
    border-bottom: 1px solid color.$gray-900;
  }
}

.colorizedRow {
  background-color: color.$white-500;
  &.lightTheme {
    background-color: color.$white-500;
  }
}

.selectedRow {
  background-color: color.$gray-900;
  &.lightTheme {
    background-color: color.$gray-900;
  }
}

.rowHover {
  background-color: color.$purple-160;
  cursor: pointer;
  &.lightTheme {
    background-color: color.$purple-160;
  }
}

.hiddenColumn {
  display: 'none';
}
